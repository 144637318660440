<template>
  <v-container class="px-0 py-0" fluid>
    <div
      v-if="!hideBanner"
      style="min-height: 140px; background-size: cover"
      :style="{
        'background-image': `url(${require('@/assets/images/activate-banner-background-3.jpeg')})`,
      }"
    >
      <v-row no-gutters>
        <v-col cols="5" style="min-height: 140px" class="d-flex justify-center">
          <SvgIcon name="ActivateLockup" :width="319" class="ma-a" />
        </v-col>
        <v-col cols="6" class="pl-2 pl-lg-0">
          <div
            class="white--text pt-3 pb-1"
            style="line-height: initial; font-size: 16px"
          >
            Join our free virtual event for event marketers. 1-hour, no fluff,
            all sessions under 10 minutes. April 25th, 2024.
          </div>
          <div>
            <v-btn
              class="gradient-btn white--text"
              tile
              large
              href="https://invt.io/1lxbxbkhss6"
              target="_blank"
            >
              Register
              <v-icon right large>mdi-menu-right</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="1" class="pt-2" align="center">
          <v-btn
            style="background: rgba(255, 255, 255, 0.5)"
            fab
            x-small
            rounded
          >
            <v-icon color="white" @click="hideActivateBanner"
              >mdi-close-thick</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
  components: { SvgIcon },
  // middleware: ['confirmation', 'account'],
  data() {
    return {
      // hideBanner: !!window.localStorage.getItem('gleaninHideActivate3Banner'),
      hideBanner: true,
    }
  },
  computed: {},
  methods: {
    hideActivateBanner() {
      window.localStorage.setItem('gleaninHideActivate3Banner', true)
      this.hideBanner = true
    },
  },
}
</script>
<style scoped>
.gradient-btn {
  background-image: linear-gradient(90deg, #9a27f6 0%, #5645f4 100%);
  font-size: 16px;
  font-weight: 400;
}
</style>
