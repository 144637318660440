<template>
  <svg
    class="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    :class="svgClassName"
    :viewBox="viewBox"
    :fill="color"
    :width="width"
    height="100%"
    :aria-labelledby="title"
    :style="{ transform: 'rotate(' + rotate + 'deg)' }"
    focusable="false"
    role="presentation"
  >
    <title v-text="title + ' icon'" />
    <description v-text="'Icon for ' + title" />
    <component :is="dynamicComponent" :key="name" @viewBox="setViewBox" />
  </svg>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 30,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
    color: {
      type: String,
      required: false,
      default: 'currentColor',
    },
    rotate: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      viewBox: '0 0 0 0',
      dynamicComponent: null,
    }
  },
  computed: {
    svgClassName() {
      const hyphenateName = this.name.replace(/([A-Z])/g, '-$1')
      const downcaseName = hyphenateName.toLowerCase()
      return 'svg' + downcaseName
    },
    title() {
      return this.name.replace(/([A-Z])/g, ' $1')
    },
  },
  watch: {
    name: {
      handler(name) {
        this.$nextTick(() => {
          this.loadDynamicComponent()
        })
      },
      immediate: true,
    },
  },
  methods: {
    loadDynamicComponent() {
      this.dynamicComponent = defineAsyncComponent(() =>
        import(`@/components/svg/${this.name}.vue`)
      )
    },
    setViewBox(viewBox) {
      this.viewBox = viewBox
    },
  },
}
</script>

<style scoped>
.svg-icon {
  display: inline-block;
  line-height: 0;
  max-width: 100%;
  height: auto;
  vertical-align: baseline;
}
</style>
