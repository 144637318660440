<template>
  <v-alert outlined dense text :icon="icon" :type="type">
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    type: {
      type: String,
      required: false,
      default: 'warning',
      validator: (val) => ['warning', 'success', 'info', 'error'].includes(val),
    },
    icon: {
      type: [String, null],
      required: false,
      default: null,
    },
  },
}
</script>
